import {t} from 'i18next'

import {OrderCancellationButtonParams} from '../../../../OrderIntake/declarations/types'

export const getCemAggOrderCancellationInfo = ({
  order,
  orderDeliveriesData,
  isCancellationRequestSent,
  isBusinessLineAllowed,
  isCancellationTimeOver,
  isOrderCancellationRequestSucceeded,
  isCancellationRequestCompleted,
  isCancellationRequestNotFound,
  isOrderStatusCancelled,
  isErrorCancellationOptions,
  isErrorCancellationRequestStatus,
  isCancelRequestFailedOnCheckSum,
  cancellationInfoText,
  getFeature
}: OrderCancellationButtonParams): {
  isOrderCancellable: boolean
  getCancellationInfoText: () => any
} => {
  const allowedToCancelStatuses = ['pending', 'confirmed', 'reserved']
  const notAllowedDivisions = ['70']
  const isOrderStatusAllowsCancellation = allowedToCancelStatuses.includes(order?.orderStatus)
  const isDivisionNotAllowed = order.headerDivisionId
    ? notAllowedDivisions.includes(order.headerDivisionId)
    : true
  const isOrderHasDeliveries = orderDeliveriesData.hasDeliveries
  const isSyncroTessOrder = order?.route?.slice(-4) === '0000'
  const isDeliveriesTooFarInProgressToCancel = !!order?.deliveries?.some(
    (delivery) => delivery.overallProcessingStatus === 'C' || delivery.totalGoodsMovement === 'C'
  )
  const isOrderWithDeliveriesCancellationEnabled = getFeature(
    'OrderIntakeOrderCancellationWithDeliveries'
  )

  const isOrderCancellable =
    isOrderStatusAllowsCancellation &&
    !(isOrderHasDeliveries
      ? isSyncroTessOrder ||
        isDeliveriesTooFarInProgressToCancel ||
        !isOrderWithDeliveriesCancellationEnabled
      : false)

  // eslint-disable-next-line complexity
  const getCancellationInfoText = () => {
    if (isCancellationRequestSent || isOrderCancellationRequestSucceeded) {
      if (!isCancellationRequestCompleted) {
        return t('orderIntake.cancellationProcessing')
      }
    }

    if (!isOrderStatusAllowsCancellation || isCancellationTimeOver) {
      return t('orderIntake.cancellationNotPossibleStatus')
    }

    if (isOrderStatusCancelled || isCancellationRequestCompleted) {
      return t('orderIntake.orderCancelledInfo')
    }

    if (!isBusinessLineAllowed || isDivisionNotAllowed) {
      return t('orderIntake.cancellationNotPossibleBusinessLine')
    }

    if (
      (isErrorCancellationOptions || isErrorCancellationRequestStatus) &&
      !isCancelRequestFailedOnCheckSum &&
      !isCancellationRequestNotFound
    ) {
      return t('orderIntake.cancellationNotPossibleError')
    }

    if (isOrderHasDeliveries) {
      if (
        isSyncroTessOrder ||
        !isOrderWithDeliveriesCancellationEnabled ||
        isDeliveriesTooFarInProgressToCancel
      ) {
        return t('orderIntake.cancellationNotPossibleDeliveries')
      }
    }

    return cancellationInfoText
  }

  return {
    getCancellationInfoText,
    isOrderCancellable
  }
}

const cancellationDisclaimers = [
  {
    version: '1',
    countries: ['GB'],
    locales: [
      {
        locale: 'en-GB',
        content:
          'For all concrete orders reduced or cancelled after 14:00 hrs on the working day prior to the booked delivery date, a charge of £25.00 per cubic metre will be made, with a minimum charge of £175. This charge will also apply to same day order reductions.'
      }
    ]
  }
]
export default cancellationDisclaimers

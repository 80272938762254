import {useNotification} from '@hconnect/uikit'
import {Box, CircularProgress, FormControlLabel, Switch, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import {TFunction} from 'i18next'
import {useEffect, useMemo, useState} from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'

import {trackEvent} from '../../../common/analytics'
import {usePostNotificationSettings} from '../../../common/react-query/hooks/mutations/usePostNotificationSettings'
import {useGetNotificationSettings} from '../../../common/react-query/hooks/queries/useGetNotificationSettings'
import {NotificationSettingsValues} from '../types/NotificationSettingsValues'
import {getDefaultValues} from '../utils/get-default-values.util'
import {mapFormValuesToRequestData} from '../utils/map-form-values-to-request-data.util'

type PODNotificationSettingsProps = {
  t: TFunction
}

export const PODNotificationSettings = ({t}: PODNotificationSettingsProps) => {
  const {notify} = useNotification()
  const {mutate} = usePostNotificationSettings()
  const [toggleValue, setToggleValue] = useState<boolean>(false)
  const {data: notificationSettings, isFetching: isNotificationSettingsLoading} =
    useGetNotificationSettings()

  const defaultValues = useMemo(
    (): NotificationSettingsValues | undefined => getDefaultValues(notificationSettings),
    [notificationSettings]
  )
  const methods = useForm<NotificationSettingsValues>({
    mode: 'onChange',
    defaultValues
  })
  const saveNotificationSettings = (value: boolean) => {
    const notificationSettings = mapFormValuesToRequestData(value)

    mutate(
      {notificationSettings: notificationSettings},
      {
        onSuccess: () => {
          trackEvent('hubNotificationSettingsWritten', {
            type: notificationSettings.type,
            option: notificationSettings.option,
            isActive: notificationSettings.isActive
          })
        },
        onError: (e) => {
          notify('error', t('orderIntake.notificationSettings.saveNotificationSettingsError'))
          trackEvent('hubNotificationSettingsError', {
            errorCode: (e as AxiosError).response?.status
          })
        },
        onSettled: () => {
          methods.reset(defaultValues)
        }
      }
    )
  }

  useEffect(() => {
    if (!isNotificationSettingsLoading) {
      setToggleValue(defaultValues?.receiveProofOfDeliveryUpdates || false)
    }
  }, [defaultValues, isNotificationSettingsLoading])

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setToggleValue(value)
    saveNotificationSettings(value)
  }

  if (isNotificationSettingsLoading) {
    return <CircularProgress />
  }

  if (!notificationSettings) {
    return null
  }
  return (
    <FormProvider {...methods}>
      <Box marginTop={4} data-test-id="pod-notification">
        <Controller
          control={methods.control}
          name="receiveProofOfDeliveryUpdates"
          render={({field}) => (
            <>
              <FormControlLabel
                {...field}
                control={
                  <Switch
                    data-test-id="notification-settings-receive-e-pod"
                    checked={toggleValue}
                    onChange={handleToggle}
                  />
                }
                labelPlacement="start"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: 0,
                  marginRight: 0
                }}
                label={
                  <Box>
                    <Typography variant="body1" fontWeight="bold" color="textPrimary">
                      {t('orderIntake.notificationSettings.receiveProofOfDeliveryUpdates.label')}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {t('orderIntake.notificationSettings.receiveProofOfDeliveryUpdates.caption')}
                    </Typography>
                  </Box>
                }
              />
            </>
          )}
        />
      </Box>
    </FormProvider>
  )
}

import {t} from 'i18next'

import {OrderCancellationButtonParams} from '../../../../OrderIntake/declarations/types'

export const getRMCOrderCancellationInfo = ({
  order,
  isCancellationRequestSent,
  isBusinessLineAllowed,
  isCancellationTimeOver,
  isOrderCancellationRequestSucceeded,
  isCancellationRequestCompleted,
  isCancellationRequestNotFound,
  isOrderStatusCancelled,
  isErrorCancellationOptions,
  isErrorCancellationRequestStatus,
  isCancelRequestFailedOnCheckSum,
  cancellationInfoText
}: OrderCancellationButtonParams): {
  isOrderCancellable: boolean
  getCancellationInfoText: () => any
} => {
  const isOrderCancellable = !!order.orderManagement?.isCancellable

  const getCancellationInfoText = () => {
    if (isCancellationRequestSent || isOrderCancellationRequestSucceeded) {
      if (!isCancellationRequestCompleted) {
        return t('orderIntake.cancellationProcessing')
      }
    }

    if (isCancellationTimeOver) {
      return t('orderIntake.cancellationNotPossibleStatus')
    }

    if (isOrderStatusCancelled || isCancellationRequestCompleted) {
      return t('orderIntake.orderCancelledInfo')
    }

    if (!isBusinessLineAllowed) {
      return t('orderIntake.cancellationNotPossibleBusinessLine')
    }

    if (
      (isErrorCancellationOptions || isErrorCancellationRequestStatus) &&
      !isCancelRequestFailedOnCheckSum &&
      !isCancellationRequestNotFound
    ) {
      return t('orderIntake.cancellationNotPossibleError')
    }

    if (!isOrderCancellable) {
      return t('orderIntake.cancellationNotPossible')
    }

    return cancellationInfoText
  }

  return {isOrderCancellable, getCancellationInfoText}
}

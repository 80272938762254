/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {dateFormatter, HCThemeType, TextButton, timeFormatter, Typography} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import CameraAlt from '@mui/icons-material/CameraAlt'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Link, Tooltip} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import {v4 as uuidV4} from 'uuid'

import {BusinessLineTypes} from '../../../common/types'
import StatusIcon, {StatusIconVariant} from '../../../Molecules/StatusIcons'
import StatusTooltip from '../../../Molecules/StatusTooltip'
import {
  downloadDocument,
  Entity,
  EntityPage,
  getDocumentFileName
} from '../../../Organisms/Documents'
import {useFeaturesState} from '../../../Organisms/Features'
import {
  DeliveryStatusAggregate,
  statusFromDeliveryStatus
} from '../../../Organisms/Orders/Deliveries.types'
import {
  Delivery,
  DeliveryStatus as DeliveryStatusEnum,
  LinkMedia,
  Rel
} from '../../Finance/Invoices/Delivery.types'
import {findDocuments, getFinishedDate} from '../Order.utils'

import {DeliveryTogglesType} from './DeliveryCard'

const useStyles = makeStyles((theme: HCThemeType) => ({
  root: {
    color: `${theme.palette.grey[500]} !important`,
    cursor: 'default !important',
    textDecoration: 'none !important',
    '& svg': {
      fill: `${theme.palette.grey[500]}`
    }
  }
}))
interface DeliveryType {
  delivery: Delivery
  toggles: DeliveryTogglesType
  customerId: string
}
type DeliveryStatusType = DeliveryType
export const DeliveryStatus: React.FC<DeliveryStatusType> = ({
  delivery,
  customerId,
  toggles: {hasInvoicePermission, isInvoiceDetailsDisabled, isRMC}
}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {deliveryStatus, links} = delivery
  const status = statusFromDeliveryStatus(
    deliveryStatus as DeliveryStatusEnum,
    hasInvoicePermission
  )
  const invoice = links ? links.find((link) => link.rel === Rel.Invoices) : undefined
  const invoiceId = invoice ? invoice.href.split('/')[1] : undefined
  const title: string = t(`order.expand.tooltip.${isRMC ? 'rmc.' : ''}${status}`)
  if (status === DeliveryStatusAggregate.Invoiced) {
    const paramsDelimiter = invoice && invoice.href.includes('?') ? '&' : '?'

    return (
      <StatusTooltip title={title} placement="right-start">
        {invoice && invoiceId && !isInvoiceDetailsDisabled(invoiceId) ? (
          <TextButton
            variant="text"
            onClick={() =>
              history.push(`/${invoice.href}${paramsDelimiter}customerId=${customerId}`)
            }
            data-test-id="DataTable-showDetails-btn"
            data-translation-id="order.expand.label.${isRMC ? 'rmc.' : ''}${status}"
          >
            {t(`order.expand.label.${isRMC ? 'rmc.' : ''}${status}`)}
          </TextButton>
        ) : (
          <Box>{t(`order.expand.label.${isRMC ? 'rmc.' : ''}${status}`)}</Box>
        )}
      </StatusTooltip>
    )
  }
  return (
    <StatusTooltip title={title} placement="right-start">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <StatusIcon
          status={deliveryStatus as DeliveryStatusEnum}
          variant={StatusIconVariant.Delivery}
          small
        />
        <Box mx={0.5} />
        <Box>{t(`order.expand.label.${isRMC ? 'rmc.' : ''}${status}`)}</Box>
      </Box>
    </StatusTooltip>
  )
}

interface DeliveryTicketNumberType extends DeliveryType {
  analytics?: any
  useDeliveryNumber?: boolean
}
export const DeliveryTicketNumber: React.FC<DeliveryTicketNumberType> = ({
  delivery,
  toggles: {isCustomerDeliveryNumber},
  customerId,
  analytics = {},
  useDeliveryNumber = false
}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {getFeature} = useFeaturesState()
  const classes = useStyles()

  const {deliveryNote, proofOfDelivery} = findDocuments(delivery)
  const ticketLink = proofOfDelivery || deliveryNote
  const {customerDeliveryNumber, deliveryNumber} = delivery
  const ticketNumber =
    isCustomerDeliveryNumber && !useDeliveryNumber
      ? customerDeliveryNumber || deliveryNumber
      : deliveryNumber
  const ticketBOLNumber = ticketNumber || ''
  const documentId = ticketLink ? ticketLink.href.split('/')[1] : ''
  const fileName = ticketLink
    ? getDocumentFileName(EntityPage.orders, Entity.delivery, ticketLink.media, t, {
        entityNumber: ticketBOLNumber
      })
    : `${Entity.delivery}.${LinkMedia.Pdf}`

  const isViewDeliveryNotesDisabled = getFeature('DisableViewDeliveryNotes')
  return (
    <>
      {ticketLink ? (
        isViewDeliveryNotesDisabled ? (
          <Link className={classes.root}>
            <Box display="flex" gap={1}>
              <PictureAsPdfIcon fontSize="small" />
              {ticketBOLNumber}
            </Box>
          </Link>
        ) : (
          <Link
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              const jobId: string = uuidV4()
              trackEvent('hubDownloadSingle', {
                product: 'hub',
                jobId: jobId,
                downloadedItem: 'orderTicket',
                linkToDownloadedItem: ticketLink.href,
                customerId: customerId,
                entryPoint: 'Order List',
                material: delivery.materialDescription,
                orderId: delivery.orderId,
                ...analytics
              })
              dispatch(downloadDocument(jobId, documentId, fileName))
            }}
            href="#"
          >
            <Box display="flex" gap={1}>
              <PictureAsPdfIcon fontSize="small" color="primary" />
              {ticketBOLNumber}
            </Box>
          </Link>
        )
      ) : (
        <Typography variant="body1">{ticketBOLNumber}</Typography>
      )}
      <Typography style={{fontSize: '12px', color: 'rgba(0, 55, 77, 0.6)'}}>
        {!ticketLink && t('order.multiple.ticketNoPDFAvailable')}
      </Typography>
    </>
  )
}

export const getVehicleDetails = (
  truckName: string | null | undefined,
  trailer: string | undefined,
  t: TFunction,
  imageProof
) => {
  if (!trailer && !truckName && imageProof) {
    return <Box>{t('order.expand.scaleImage')}</Box>
  } else if (!trailer && !truckName && !imageProof) {
    return <Box>-</Box>
  } else if (!trailer && truckName) {
    return <Box>{truckName}</Box>
  } else if (trailer && !truckName) {
    return <Box>{trailer}</Box>
  } else if (trailer && truckName && trailer === truckName) {
    return <Box>{truckName}</Box>
  } else if (trailer && truckName && trailer !== truckName) {
    return (
      <Box display="flex" flexDirection="column" alignItems={'left'}>
        <Box>{truckName}</Box>
        <Box>
          {trailer} ({t('order.expand.trailer')})
        </Box>
      </Box>
    )
  }
}

interface DeliveryVehicleType extends DeliveryType {
  analytics?: any
}
export const DeliveryVehicle: React.FC<DeliveryVehicleType> = ({
  delivery,
  customerId,
  toggles: {useTruckLicensePlate, isCustomerDeliveryNumber},
  analytics = {}
}) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {imageProof} = findDocuments(delivery)
  const {customerDeliveryNumber, deliveryNumber, truckName, truckLicensePlate} = delivery
  const truck = useTruckLicensePlate ? truckLicensePlate : truckName
  const documentId = imageProof ? imageProof.href.split('/')[1] : ''
  const fileNameExt = '_truck'
  const fileExt = imageProof && imageProof.media ? imageProof.media.split('/')[1] : ''
  const ticketNumber = isCustomerDeliveryNumber
    ? customerDeliveryNumber || deliveryNumber
    : deliveryNumber
  const vehicleDetails = getVehicleDetails(
    truck,
    delivery.transportVehicleDetails?.trailer,
    t,
    imageProof
  )
  if (imageProof) {
    return (
      <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
        <Box mr={0.75} mb={-0.75}>
          <CameraAlt color="primary" fontSize="medium" />
        </Box>
        <Box>
          <Link
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              const jobId: string = uuidV4()
              trackEvent('hubDownloadSingle', {
                product: 'hub',
                jobId: jobId,
                downloadedItem: 'imageProof',
                linkToDownloadedItem: imageProof.href,
                customerId: customerId,
                entryPoint: 'Order Image Proof',
                orderId: delivery.orderId,
                loadQty: delivery.loadQuantity,
                loadQtyUoM: delivery.loadQuantityUom,
                material: delivery.materialDescription,
                ...analytics
              })
              dispatch(
                downloadDocument(jobId, documentId, `${ticketNumber}${fileNameExt}.${fileExt}`)
              )
            }}
            href="#"
          >
            {vehicleDetails}
          </Link>
        </Box>
      </Box>
    )
  }
  return <>{vehicleDetails}</>
}

export type DeliveryArrivalTimeType = Pick<DeliveryType, 'delivery'>

export const DeliveryArrivalTime: React.FC<DeliveryArrivalTimeType> = ({delivery}) => {
  const isEstimated = delivery.deliveryEventsAccuracy?.arrived === 'calculated'
  return <DeliveryEventDate date={delivery.deliveryEvents.arrived} isEstimated={isEstimated} />
}

export const TimeAndDateDisplayedForDoneColumn: React.FC<{delivery: Delivery}> = ({delivery}) => {
  const {getFeature} = useFeaturesState()
  const leftCustomerEnabled = getFeature('DeliveryLeftCustomerEnabled')
  const {
    deliveryEvents: {unloadFinished, leftCustomer},
    deliveryEventsAccuracy: accuracy
  } = delivery
  const dateTimeToUse = leftCustomerEnabled ? leftCustomer : unloadFinished
  const isEstimated =
    (leftCustomerEnabled ? accuracy?.leftCustomer : accuracy?.unloadFinished) === 'calculated'

  return <DeliveryEventDate date={dateTimeToUse} isEstimated={isEstimated} />
}

export const DeliveryLeftAt: React.FC<{
  delivery: Delivery
  isDeliveryLoadFinished: boolean
  isSloCountry: boolean
  businessLine?: BusinessLineTypes
}> = ({delivery, isDeliveryLoadFinished, isSloCountry, businessLine}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const {date, accuracy} = getFinishedDate(delivery, isDeliveryLoadFinished, businessLine)
  if (isSloCountry && date) return <Box>{dateFormatter(date, language)}</Box>
  const isEstimated = accuracy === 'calculated'
  return <DeliveryEventDate date={date} isEstimated={isEstimated} />
}

export const DeliveryUnloadFinished: React.FC<{delivery: Delivery}> = ({delivery}) => {
  const {unloadFinished} = delivery.deliveryEvents
  const isEstimated = delivery.deliveryEventsAccuracy?.unloadFinished === 'calculated'
  return <DeliveryEventDate date={unloadFinished} isEstimated={isEstimated} />
}

const DeliveryEventDate = ({
  date,
  isEstimated
}: {
  date: string | null | undefined
  isEstimated: boolean
}) => {
  const {
    i18n: {language, t}
  } = useTranslation()

  if (!date) return <Box>-</Box>

  const formattedTime = timeFormatter(date, language)

  return (
    <>
      {isEstimated ? (
        <Tooltip title={t('order.expand.estimated')} data-test-id="estimated-tooltip">
          <Box>
            {formattedTime}
            {'*'}
          </Box>
        </Tooltip>
      ) : (
        <Box>{formattedTime}</Box>
      )}
      <Typography color="secondary" variant="subtitle1">
        {dateFormatter(date, language)}
      </Typography>
    </>
  )
}

export const PricingInformation: React.FC<{delivery: Delivery}> = ({delivery}) => {
  // check if delivery.pricing is null or empty object
  if (!delivery.pricing) {
    return null
  }
  const {unitPrice} = delivery.pricing
  return (
    <Box>
      {currencyFormatter.format(unitPrice, {
        symbol: '€/t',
        decimal: ',',
        thousand: '.',
        precision: 2,
        format: '%v %s'
      })}
    </Box>
  )
}

/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
import {User} from '@hconnect/apiclient'
import {useNotification, Typography} from '@hconnect/uikit'
import {Box, Button, CircularProgress} from '@material-ui/core'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import {AxiosError} from 'axios'
import moment from 'moment'
import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {trackEvent} from '../../../../common/analytics'
import {useCancelOrder} from '../../../../common/react-query/hooks/mutations/useCancelOrder'
import {useGetCancellationRequestStatus} from '../../../../common/react-query/hooks/queries/useGetCancellaionRequestStatus'
import {useGetOrderCancellationOptions} from '../../../../common/react-query/hooks/queries/useGetOrderCancellationOptions'
import {BusinessLineType} from '../../../../common/types'
import {useOrderIntakeStyles} from '../../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {OrderCancellationCutOffTime} from '../../../../OrderIntake/components/OrderCancellationCutOffTime'
import {OrderCancellation} from '../../../../OrderIntake/declarations/types'
import {RequestForQuoteResponse} from '../../../../OrderIntake/RequestForQuoteFormValues'
import {
  OrderCancellationStateType,
  useOrderCancellationState
} from '../../../../Organisms/Cancellation/OrderCancellationService'
import {useFeaturesState} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {getUserProfile} from '../../../../UserProfile/UserProfile.selectors'
import {useCheckOrdersWithDeliveries} from '../../hooks/useCheckOrdersWithDeliveries'
import {Order} from '../../Order.types'

import {getCemAggOrderCancellationInfo} from './getCemAggOrderCancellationInfo'
import {getRMCOrderCancellationInfo} from './getRMCOrderCancellationInfo'
import {DisclaimerBanner} from './DisclaimerBanner'
import {useCancellationDisclaimerContents} from './hooks/useCancellationDisclaimers'

interface OrderCancellationButtonProps {
  order: Order
}

export const OrderCancellationButton: React.FC<OrderCancellationButtonProps> = ({order}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const classes = useOrderIntakeStyles()
  const {mutate} = useCancelOrder()
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const {OrderCancellationState, setOrderCancellationState} = useOrderCancellationState()
  const [askForConfirmation, setAskForConfirmation] = useState(false)
  const [cancellationInfoText, setCancellationInfoText] = useState('')
  const [isLoadingCancellation, setIsLoadingCancellation] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [hideCancellationButton, setHideCancellationButton] = useState(false)
  const [isCancellationTimeOver, setIsCancellationTimeOver] = useState(false)

  const {data: cancellationRequestStatus, isError: isErrorCancellationRequestStatus} =
    useGetCancellationRequestStatus(order.orderId, order.orderStatus)

  const orderDeliveriesData = useCheckOrdersWithDeliveries(order)

  const {getFeature} = useFeaturesState()

  const {
    data: cancellationOptions,
    isLoading: isLoadingCancellationOptions,
    isError: isErrorCancellationOptions
  } = useGetOrderCancellationOptions(order.orderId)

  const hideCutOffTime = isErrorCancellationOptions || hideCancellationButton

  const isCancelRequestFailedOnCheckSum: boolean = OrderCancellationState.some(
    (item) => item.orderItemNumber === order.orderNumber && item.isFailed
  )
  const isOrderCancellationRequestSucceeded = OrderCancellationState.some(
    (item) => item.orderItemNumber === order.orderNumber && !item.isFailed
  )

  const isBusinessLineAllowed = cancellationOptions?.isBusinessLineAllowed

  const isCancellationRequestSent = cancellationRequestStatus?.status === 'sent'
  const isCancellationRequestCompleted = cancellationRequestStatus?.status === 'completed'
  const isCancellationRequestFailed = cancellationRequestStatus?.status === 'failed'
  const isCancellationRequestNotFound = cancellationRequestStatus === undefined

  const isOrderStatusCancelled = (order?.orderStatus as string) === 'cancelled'

  const {data: disclaimerData} = useCancellationDisclaimerContents(order.customer.countryId)

  const cancellationParams = {
    order,
    isCancellationRequestSent,
    isBusinessLineAllowed,
    isCancellationTimeOver,
    isOrderCancellationRequestSucceeded,
    isCancellationRequestCompleted,
    isCancellationRequestNotFound,
    isOrderStatusCancelled,
    isErrorCancellationOptions,
    isErrorCancellationRequestStatus,
    isCancelRequestFailedOnCheckSum,
    cancellationInfoText,
    orderDeliveriesData,
    getFeature
  }

  const {getCancellationInfoText, isOrderCancellable} = (() => {
    if (BusinessLineType.RMC === order.businessLine) {
      return getRMCOrderCancellationInfo(cancellationParams)
    } else {
      return getCemAggOrderCancellationInfo(cancellationParams)
    }
  })()

  const shouldButtonBeDisabled =
    !isOrderCancellable ||
    isLoadingCancellationOptions ||
    isErrorCancellationOptions ||
    !isBusinessLineAllowed ||
    isCancelRequestFailedOnCheckSum ||
    isCancellationRequestSent

  useEffect(() => {
    setIsButtonDisabled(shouldButtonBeDisabled)

    setIsLoadingCancellation(orderDeliveriesData.isDeliveriesFetching)
    setAskForConfirmation(false)
    setHideCancellationButton(false)

    if (isCancellationRequestSent || isOrderCancellationRequestSucceeded) {
      setIsButtonDisabled(true)
    }

    if (isCancellationRequestCompleted) {
      setHideCancellationButton(true)
      setCancellationInfoText(t('orderIntake.orderCancelledInfo'))
    }

    if (isCancellationRequestFailed && !shouldButtonBeDisabled) {
      setIsButtonDisabled(false)
      setHideCancellationButton(false)
    }

    if (isCancellationRequestNotFound && isCancelRequestFailedOnCheckSum) {
      setIsButtonDisabled(true)
      setCancellationInfoText(t('orderIntake.cancellationFailedCheckSum'))
    }
  }, [
    shouldButtonBeDisabled,
    order,
    isErrorCancellationOptions,
    setIsButtonDisabled,
    isLoadingCancellationOptions,
    isOrderStatusCancelled,
    orderDeliveriesData,
    isCancellationRequestSent,
    isCancellationRequestCompleted,
    isCancellationRequestFailed
  ])

  const cancelOrder = () => {
    const orderId = order.orderId
    const cancelOrderPayload: OrderCancellation = {
      cancelReasonNotes: 'cancellation request',
      checkSum: order.checkSum,
      timeZone: moment.tz.guess()
    }

    setIsLoadingCancellation(true)
    setAskForConfirmation(false)
    trackEvent('hubOrderIntakeCancellationRequestSubmitted', {
      businessLine: order.businessLine,
      cancelledByOrderPlacer: order.orderPlacerName === userProfile?.name,
      country: order.customer.countryId,
      customerId: order.customer.customerId,
      orderId: order.orderId
    })

    mutate(
      {orderId, cancelOrderPayload},
      {
        onSuccess: () => {
          setIsLoadingCancellation(false)
          setHideCancellationButton(true)
          notify('success', t('orderIntake.cancellationSucceeded'))
          setCancellationInfoText(t('orderIntake.cancellationProcessing'))

          setOrderCancellationState(
            (currentState: OrderCancellationStateType[]): OrderCancellationStateType[] => {
              const orderIndex = currentState.findIndex(
                (item: OrderCancellationStateType) => item.orderItemNumber === order.orderNumber
              )

              if (orderIndex !== -1) {
                return currentState.map((item: OrderCancellationStateType, index: number) =>
                  index === orderIndex ? {...item, isFailed: false} : item
                )
              } else {
                return [...currentState, {orderItemNumber: order.orderNumber, isFailed: false}]
              }
            }
          )
        },
        onError: (error) => {
          setIsLoadingCancellation(false)

          const errorData = (error as AxiosError).response?.data as RequestForQuoteResponse
          const errorDetails = errorData?.detail

          if (!!errorDetails && errorDetails.includes('CheckSum is invalid')) {
            setIsButtonDisabled(true)
            setOrderCancellationState([
              ...OrderCancellationState,
              {isFailed: true, orderItemNumber: order.orderNumber}
            ])
            notify('error', t('orderIntake.cancellationFailedCheckSum'), {persist: true})
            setCancellationInfoText(t('orderIntake.cancellationFailedCheckSum'))

            trackEvent('hubOrderCancelOrderNotCancelled', {
              errorMessage: errorDetails,
              country: order.customer.countryId,
              customerId: order.customer.customerId,
              orderId: order.orderId
            })
          } else {
            notify('error', t('orderIntake.cancellationFailed'))
          }
        }
      }
    )
  }

  const handleAbortCancellation = () => {
    setAskForConfirmation(false)
  }

  const handleCancellation = () => {
    if (!askForConfirmation) {
      setAskForConfirmation(true)
      return
    } else {
      cancelOrder()
    }
  }

  const getConfirmationButtonContent = () => {
    if (isLoadingCancellation) {
      return <CircularProgress size={20} color="inherit" />
    } else if (isOrderStatusCancelled) {
      return t('orderIntake.orderCancelled')
    } else {
      return !askForConfirmation
        ? t('orderIntake.cancelOrder')
        : t('orderIntake.confirmCancellation')
    }
  }

  const handelTimeToCancelOver = (isTimeToCancelOver: boolean) => {
    /*
     If shouldButtonBeDisabled is true, we don't need to check for cut off time
    */
    if (shouldButtonBeDisabled || isButtonDisabled || isCancellationRequestSent) return

    setIsButtonDisabled(isTimeToCancelOver)
    setIsCancellationTimeOver(isTimeToCancelOver)
    isTimeToCancelOver && setCancellationInfoText(t('orderIntake.cancellationNotPossibleStatus'))
  }

  return (
    <>
      <Box
        width={1}
        mt={2}
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        data-test-id="order-cancellation-buttons"
      >
        {!hideCancellationButton && !isOrderStatusCancelled ? (
          <Box width={1} display="flex" flexDirection="column" alignItems="center">
            {askForConfirmation && !isButtonDisabled && (
              <Box width={0.9} mb={2}>
                <DisclaimerBanner
                  title={t('orderIntake.cancellationDisclaimerDefaultTitle')}
                  message={
                    disclaimerData?.data.content ?? t('orderIntake.cancellationDisclaimerDefault')
                  }
                  dataTestId="order-cancellation-disclaimer"
                />
              </Box>
            )}
            <Box width={0.9}>
              <Button
                className={classes.cancelOrderButton}
                onClick={handleCancellation}
                startIcon={isLoadingCancellation || isOrderStatusCancelled ? null : <CloseIcon />}
                disabled={isButtonDisabled || isLoadingCancellation}
                data-test-id="cancel-order-button"
              >
                {getConfirmationButtonContent()}
              </Button>
            </Box>
            <Box mt={2} />
            {askForConfirmation && (
              <Box width={0.9}>
                <Button
                  className={classes.orderButton}
                  onClick={handleAbortCancellation}
                  startIcon={<ArrowBackIcon />}
                  data-test-id="abort-cancellation-button"
                >
                  {t('orderIntake.abortCancellation')}
                </Button>
              </Box>
            )}
          </Box>
        ) : null}
        {(isButtonDisabled || hideCancellationButton) && !isOrderStatusCancelled ? (
          <Box textAlign={'center'}>
            <Typography
              variant="body2"
              customColor="textPrimarySoft"
              style={{fontWeight: 700, fontSize: 14}}
              data-test-id="cancellation-info-text"
            >
              {getCancellationInfoText()}
            </Typography>
          </Box>
        ) : null}
        <Box mt={1} />
        {!hideCutOffTime && (
          <OrderCancellationCutOffTime
            order={order}
            onCancelCutOffTimeReached={handelTimeToCancelOver}
            cancellationOptions={cancellationOptions}
            isLoadingCancellationOptions={isLoadingCancellationOptions}
            isCancellationButtonDisabled={shouldButtonBeDisabled || isButtonDisabled}
          />
        )}
      </Box>
    </>
  )
}

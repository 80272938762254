import {Typography} from '@hconnect/uikit'
import {Box, makeStyles} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import React from 'react'

interface DisclaimerBannerProps {
  title: string
  message: string
  dataTestId: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255)',
    minHeight: theme.spacing(12),
    paddingTop: `${theme.spacing(1.5)}px`
  },
  title: {
    paddingLeft: theme.spacing(1)
  },
  message: {
    paddingTop: theme.spacing(2)
  }
}))

export const DisclaimerBanner: React.FC<DisclaimerBannerProps> = ({title, message, dataTestId}) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      className={classes.container}
      data-test-id={dataTestId}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">
        <Cancel color="error" />
        <Typography variant="h3" color="textPrimary" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box />
      <Typography variant="h4" color="textPrimary" className={classes.message}>
        {message}
      </Typography>
      <Box />
    </Box>
  )
}

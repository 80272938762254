import {
  DatePicker,
  MOMENTJS_DATE_FORMAT,
  oneWeekDates,
  ongoingDates,
  prevMonthDates
} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import {Moment} from 'moment'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {PageNames} from '../../../common/constants'
import {useQueryParam} from '../../../Hooks/useQueryParam'
import {useFeaturesState} from '../../../Organisms/Features'
import {AppState} from '../../../Root.store'
import {useTrackFilterEvents} from '../../../TrackEvents/hubFilterEvents'
import {switchView} from '../Order.action'
import {OrderViews} from '../Order.enum'
import {removeLookupFilter, selectDateFilter, setDateFilter} from '../Order.filters'

const useStyle = makeStyles(() => ({
  text: {height: 60}
}))

interface DateFilterType {
  filter: {startDate: Moment; endDate: Moment}
  setFilter: (props: {startDate: string | null; endDate: string | null}) => void
}
const DateFilter: React.FC<DateFilterType> = ({filter = {}, setFilter, ...props}) => {
  const classes = useStyle()
  const {startDate, endDate} = filter as {startDate: Moment; endDate: Moment}
  const {t} = useTranslation()
  const queryParam = useQueryParam()
  const history = useHistory()
  const {trackDateFilterEvent} = useTrackFilterEvents()
  const {getFeature} = useFeaturesState()
  const showMaxDayMessage = getFeature('MaxDayRangeMessage')
  const showMax90DateRange = getFeature('MaxDayRange90')
  const showMax31DateRange = getFeature('MaxDayRange31')

  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'one-week',
      title: t('order.datePicker.oneWeek'),
      type: 'range',
      dates: oneWeekDates(locale)
    },
    {
      key: 'today',
      title: t('order.datePicker.today'),
      type: 'singleDate',
      dates: ongoingDates(locale)
    },
    {
      key: 'last-month',
      title: t('order.datePicker.lastMonth'),
      type: 'range',
      dates: prevMonthDates(locale)
    }
  ]

  const setDateToUrl = (
    startDate: Moment | undefined | null,
    endDate: Moment | undefined | null
  ) => {
    if (startDate && endDate)
      history.replace({
        search: queryString.stringify({
          dateFrom: startDate.startOf('day').toISOString(),
          dateTo: endDate.endOf('day').toISOString()
        })
      })
  }

  const endDateFromUrl = queryParam.get('dateTo')
  const startDateFromUrl = queryParam.get('dateFrom')

  return (
    <DatePicker
      data-test-id="order-filter-datepicker"
      startDate={startDateFromUrl ? moment(startDateFromUrl) : startDate}
      endDate={endDateFromUrl ? moment(endDateFromUrl) : endDate}
      textClasses={classes.text}
      hideClearButton
      {...(showMax31DateRange && {
        maxDayRange: 31
      })}
      {...(showMax90DateRange && {
        maxDayRange: 90
      })}
      {...(showMaxDayMessage && {
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={({startDate, endDate}) => {
        setDateToUrl(startDate, endDate)
        setFilter({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null
        })
        trackDateFilterEvent(PageNames.ORDERS, {
          startDate: startDate ? startDate.format(MOMENTJS_DATE_FORMAT) : null,
          endDate: endDate ? endDate.format(MOMENTJS_DATE_FORMAT) : null
        })
      }}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      {...props}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  filter: selectDateFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: OrderViews.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setDateFilter(props))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)

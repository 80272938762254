import {withRoute} from '@hconnect/uikit'

import {NotificationSettingsPage} from './NotificationSettingsPage'

export const ROUTE = '/notificationSettings'

export default withRoute({
  path: ROUTE,
  label: 'NotificationSettings',
  labelKey: 'orderIntake.notificationSettings.navigationLabel',
  order: 1000,
  hideInMainNav: true,
  routeProps: {exact: true}
})(NotificationSettingsPage)

import {Typography} from '@hconnect/uikit'
import EastIcon from '@mui/icons-material/East'
import {Box, Button, CircularProgress, Grid, Paper, styled} from '@mui/material'
import {blue} from '@mui/material/colors'
import {t} from 'i18next'
import React from 'react'

import {AlertWithLeftBorder} from '../../../Molecules/Alert/AlertWithLeftBorder'
import {LeadSubscriptionsStatus} from '../../../Molecules/LeadBanners/types'
import {BoldText} from '../../../Molecules/VerticalTabsShowcase/components'

import {BigHeader} from './Typography'

const PartnerButton = styled(Button)({
  minWidth: 227,
  minHeight: 48,
  padding: '13px 32px',
  textTransform: 'none',
  backgroundColor: blue[700],
  color: '#FFFFFF',
  cursor: 'pointer',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: blue[800]
  },
  '&:active': {
    backgroundColor: blue[900]
  },
  '&:disabled': {
    backgroundColor: blue[200],
    color: '#FFFFFF',
    '& svg': {
      fill: '#FFFFFF'
    }
  }
})

interface Type {
  cardId: string
  title: string
  subtitle: string
  moto?: string
  action: () => void
  handleHover: () => void
  alert?: {
    type: 'info' | 'success' | 'error'
    text: string
  }
  leadStatus?: LeadSubscriptionsStatus
  imageUrls: {lg?: string; md: string; sm: string; xs?: string}
  isFetching: boolean
  smallScreen?: boolean
  logo?: string
}

export const PartnerCard: React.FC<Type> = ({
  cardId,
  title,
  subtitle,
  moto,
  action,
  alert,
  leadStatus,
  imageUrls,
  isFetching,
  handleHover,
  smallScreen = false,
  logo
}) => (
  <Paper
    elevation={1}
    data-test-id={`partner-card-${cardId}`}
    sx={{
      borderRadius: 1,
      px: smallScreen ? 2.5 : 6.25,
      py: 5.5
    }}
  >
    <Grid container columnSpacing={20} rowSpacing={4}>
      <Grid item lg={5} md={5} xl={5} xs={12} sm={12}>
        <Box data-test-id={`partner-card-content-${cardId}`} display="flex" flexDirection="column">
          {logo ? (
            <Box mb={2.75}>
              <img src={logo} width="117px" alt="" />
            </Box>
          ) : null}
          <Box mb={1}>
            <BigHeader variant="h3" gutterBottom>
              {title}
            </BigHeader>
          </Box>
          <Box mb={2}>
            <Typography variant="body1" gutterBottom>
              {subtitle}
            </Typography>
          </Box>
          {moto ? (
            <Box mb={4}>
              <BoldText variant="body1" gutterBottom>
                {moto}
              </BoldText>
            </Box>
          ) : null}
          {!leadStatus || ['expired', 'cannotSubscribe'].includes(leadStatus) ? null : (
            <Box data-test-id="partner-alerts" mb={4}>
              <AlertWithLeftBorder type={alert?.type || 'info'} message={alert?.text || ''} />
            </Box>
          )}
          {!leadStatus || ['expired', 'cannotSubscribe'].includes(leadStatus) ? null : (
            <Box data-test-id="partner-card-action" color="#ffffff">
              <PartnerButton
                data-test-id={`c60-button-${cardId}`}
                onClick={(event) => {
                  event.preventDefault()
                  action()
                }}
                onMouseEnter={() => handleHover()}
                variant="contained"
                disabled={isFetching || alert?.type === 'success'}
                startIcon={<EastIcon />}
              >
                {isFetching ? <CircularProgress size={24} /> : t('lead.c60.requestMoreInfo')}
              </PartnerButton>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item md={7} lg={7} xl={7} sm={12} xs={12}>
        <Box
          data-test-id={`partner-card-image-${cardId}`}
          display="flex"
          alignItems="flex-start"
          justifyContent={smallScreen ? 'center' : 'flex-end'}
        >
          <img
            style={{overflow: 'auto'}}
            srcSet={`${imageUrls.sm} 728w, ${imageUrls.md} 1455w`}
            sizes="(max-width: 480px) 300px, (min-width: 901px) 780px"
            src={imageUrls.md}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  </Paper>
)

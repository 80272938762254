import {Box, Grid, Paper, Typography} from '@mui/material'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'

import EmailNotification from '../../../Assets/notificationSettings/emailNotification.svg'
import {Features} from '../../../Organisms/Features'
import {useNotificationSettingsStyles} from '../hooks/useNotificationSettingsStyles'

import {PODNotificationSettings} from './PODNotifications'
import {ScheduledExports} from './ScheduledExports'

export const NotificationSettingsForm = () => {
  const {t} = useTranslation()
  const c = useNotificationSettingsStyles()

  return (
    <Grid container spacing={2} marginTop={0} className={c.fullHeightContainer}>
      <Grid item xs={12} md={8}>
        <Paper elevation={4} sx={{height: '100%'}}>
          <Grid container className={c.container} data-test-id="notification-settings-container">
            <Box display="flex" flexDirection="column">
              <Box>
                <Typography variant="h3">
                  {t('orderIntake.notificationSettings.emailNotifications')}
                </Typography>
                <Typography color="secondary" variant="body2" marginTop={1}>
                  {t('orderIntake.notificationSettings.emailNotificationsDescription')}
                </Typography>
              </Box>
              <Features name="ShowNotificationSettings">
                <PODNotificationSettings t={t} />
              </Features>
              <ScheduledExports t={t} />
            </Box>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper elevation={4}>
          <Box
            className={classNames(c.container, c.imageContainer)}
            data-test-id="notification-settings-image-container"
          >
            <img src={EmailNotification} alt="" height="200x" />
            <Box mb={1}>
              <Typography variant="body2" color="secondary" className={c.textCenterAlignment}>
                {t('orderIntake.notificationSettings.emailNotificationsInfo')}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

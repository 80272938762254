import {Page} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import Content from '../../Molecules/Content'

import {NotificationSettingsForm} from './components/NotificationSettingsForm'

const NotificationSettingsPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Content>
      <Page
        data-test-id="request-for-quote-page"
        title={t('orderIntake.notificationSettings.navigationLabel')}
      >
        <NotificationSettingsForm />
      </Page>
    </Content>
  )
}

export {NotificationSettingsPage}

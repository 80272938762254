import {Typography} from '@hconnect/uikit'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Button, Card, Checkbox} from '@mui/material'
import {TFunction} from 'i18next'
import {useState} from 'react'

import {CustomHeader} from '../../../Molecules/CustomHeader'
import {Features} from '../../../Organisms/Features'
import {Delivery, LinkType, RelatedEntity} from '../../Finance/Invoices/Delivery.types'
import {Order} from '../Order.types'
import {isDeliveryNoteOrProofOfDelivery} from '../Order.utils'

interface DownloadOrdersCardProps {
  orders: Order[]
  bulkDownload: (
    deliveryIds: string[],
    orderIds: string[],
    ticketsCount: number,
    combined: boolean
  ) => void
  t: TFunction
}
export const DownloadOrdersCard: React.FC<DownloadOrdersCardProps> = ({
  orders,
  bulkDownload,
  t
}: DownloadOrdersCardProps) => {
  const [ticketsChecked, setTicketsChecked] = useState<boolean>(true)
  const [orderConfirmationsChecked, setOrderConfirmationsChecked] = useState<boolean>(true)

  const allDeliveries = orders.map((order) => order.deliveriesFromOrder || []).flat()
  const {deliveriesWithTicket, deliveriesWithoutTicket} = allDeliveries.reduce(
    (agg, delivery) => {
      const hasTicket = delivery.links?.some(isDeliveryNoteOrProofOfDelivery)
      return {
        deliveriesWithTicket: [...agg.deliveriesWithTicket, ...(hasTicket ? [delivery] : [])],
        deliveriesWithoutTicket: [...agg.deliveriesWithoutTicket, ...(hasTicket ? [] : [delivery])]
      }
    },
    {deliveriesWithTicket: [] as Delivery[], deliveriesWithoutTicket: [] as Delivery[]}
  )

  const ticketsCount = deliveriesWithTicket.filter((delivery) =>
    (delivery.links || []).some(isDeliveryNoteOrProofOfDelivery)
  ).length

  const ordersWithConfirmations = orders.filter(
    (order) =>
      order.links?.some(
        (link) => link.rel === RelatedEntity.documents && link.type === LinkType.OrderConfirmation
      )
  )

  const filesCount =
    (ticketsChecked ? ticketsCount : 0) +
    (orderConfirmationsChecked ? ordersWithConfirmations.length : 0)

  const canDownload = filesCount > 0
  const canCombine = filesCount > 1

  const getDeliveryIds = () =>
    ticketsChecked ? deliveriesWithTicket.map((delivery) => delivery?.deliveryId) : []

  const getOrderIds = () =>
    orderConfirmationsChecked ? ordersWithConfirmations.map((order) => order?.orderId) : []

  return (
    <Card variant="outlined" sx={{padding: 2}} data-test-id="orders-download-options-card">
      <CustomHeader title={t('order.bulkDownload.downloadOptions')} color="secondary" />

      <Box display="flex" alignItems="center">
        <Checkbox
          checked={ticketsChecked}
          onChange={(_, checked) => setTicketsChecked(checked)}
          data-test-id="download-order-tickets-checkbox"
        />
        <Typography style={{flexGrow: 1}}>{t('order.bulkDownload.tickets')}</Typography>
        <Typography>{ticketsCount}</Typography>
      </Box>

      <Features name="OrderDocuments">
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={orderConfirmationsChecked}
            onChange={(_, checked) => setOrderConfirmationsChecked(checked)}
            data-test-id="download-order-confirmations-checkbox"
          />
          <Typography style={{flexGrow: 1}}>
            {t('order.bulkDownload.orderConfirmations')}
          </Typography>
          <Typography>{ordersWithConfirmations.length}</Typography>
        </Box>
      </Features>

      <Box display="flex" alignItems="center">
        <Typography style={{flexGrow: 1}}>
          {t('order.bulkDownload.deliveriesWithoutDocuments')}
        </Typography>
        <Typography>{deliveriesWithoutTicket.length}</Typography>
      </Box>

      <Box mt={3} />

      <Button
        color="primary"
        variant="contained"
        startIcon={<DownloadOutlinedIcon />}
        disabled={!canDownload}
        onClick={() => bulkDownload(getDeliveryIds(), getOrderIds(), ticketsCount, false)}
        data-test-id="download-selected-pdfs-button-orders"
      >
        {t('order.bulkDownload.downloadSelectedPDFs')}
      </Button>
      <Box mt={3} />
      <Box>
        <Button
          variant="outlined"
          style={{
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            height: 48,
            color: '#29aaff',
            textTransform: 'none',
            border: '1px solid rgba(0, 39, 77, 0.1)',
            padding: '13px 40px 13px 40px',
            cursor: 'pointer'
          }}
          color="primary"
          startIcon={<PictureAsPdfIcon />}
          disabled={!canDownload || !canCombine}
          onClick={() => bulkDownload(getDeliveryIds(), getOrderIds(), ticketsCount, true)}
          data-test-id="combine-to-pdf-and-download-button-orders"
        >
          {t('invoice.multiple.combineToPdfAndDownload')}
        </Button>
      </Box>
    </Card>
  )
}

/* eslint-disable max-statements */
import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {
  ErrorText,
  Page,
  dateRangeExceedsOneYear,
  getDateRangeInDays,
  oneWeekDates,
  ResponsiveTable,
  Typography,
  dateRangeFormatter
} from '@hconnect/uikit'
import {Box, Paper, Grid, Slide} from '@mui/material'
import assignIn from 'lodash/assignIn'
import get from 'lodash/get'
import keys from 'lodash/keys'
import moment, {MomentInput} from 'moment'
import queryString from 'query-string'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation, useHistory} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes,
  SheetRequestType
} from '../../AsyncJobQueue/AsyncJobQueue.types'
import {PageNames} from '../../common/constants'
import Content from '../../Molecules/Content'
import {
  CustomerFeedbackBanner,
  CustomerFeedbackPanel
} from '../../Molecules/CustomerFeedbackProgramme/'
import {DocumentActionsBar} from '../../Molecules/DocumentActionsBar'
import {ExportDropdown, ExportScheduledInfo, ScheduledExport} from '../../Molecules/Export'
import {InformationBanner} from '../../Molecules/InformationBanner/InformationBanner'
import {LoadMore} from '../../Molecules/LoadMore'
import {OpenInNewTabButton} from '../../Molecules/OpenInNewTab'
import {
  ResponsiveHeightWithFilters,
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../Molecules/Responsive.utils'
import {ResultsForMessage} from '../../Molecules/ResultsForMessage'
import {useBranding} from '../../Organisms/Branding'
import {selectSelectedCustomer} from '../../Organisms/Customers'
import {Customer} from '../../Organisms/Customers/Customers.types'
import {useFeaturesState} from '../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {OrderRequestBanner} from '../../Organisms/OrderRequestBanner'
import {FailedOrderRequestBanner} from '../../Organisms/OrderRequestBanner/OrderRequestBanner'
import {selectProject} from '../../Organisms/Projects'
import ResultsPerPageSelector from '../../Organisms/ResultsPerPage/ResultsPerPageSelector'
import {Site} from '../../Organisms/SitesTypeahead'
import {AppState} from '../../Root.store'

import {OrderNotSelected, OrdersCardList, SelectedOrdersAside} from './components'
import {OrderIntakeButtons} from './components/OrderIntakeButtons'
import Filters, {FilterBarErrorOrder} from './Filters'
import {LookupSelectItems, OrderViews} from './Order.enum'
import {OrderRows} from './Order.rows'
import {Order} from './Order.types'
import {useAllFeaturesOrderItems} from './Order.utils'
import {OrderDetailsSM} from './OrderDetailsSM/OrderDetailsSM'

interface OrderPageType {
  fetchOrders: (
    isGroupedDeliveries: boolean,
    isFilteringOutCancelledDeliveries?: boolean,
    isFilteringCancelledOrders?: boolean,
    isPumpServiceIncluded?: boolean,
    isServiceOrderIncluded?: boolean,
    includeDeliveryInstructions?: boolean,
    expandToOrderDocuments?: boolean,
    useHaulierView?: boolean,
    siteId?: string,
    lookupAnalyticsId?: string
  ) => void
  items: Order[]
  view: OrderViews
  sortOrder: {key: string; asc: boolean}
  setSortKey: (sortKey: string) => void
  setDefaultDateFilter: ({startDate, endDate}: {startDate: string; endDate: string}) => void
  setDateFilter: ({startDate, endDate}: {startDate: string; endDate: string}) => void
  defaultFilters: any
  filters: any
  error: any
  errorsClear: () => void
  skip: number
  limit: number
  showLoadMore: boolean
  loadMore: () => void
  isFetching: boolean
  sheetRequest: ({criteria, type, format, name}: SheetRequestType) => void
  dateFilter: any
  getMultiDownloadCheckboxStateDelivery: any
  toggleResetOrderIntake: () => void
  setLookupFilter: ({entity, value, label}) => void
  setGlobalResultsPerPage: (amount: number) => void
}

// eslint-disable-next-line complexity
const OrderPage: React.FC<OrderPageType> = ({
  fetchOrders,
  items,
  view,
  sortOrder: {key: sortedBy, asc: sortAscending},
  setSortKey,
  setDefaultDateFilter,
  setDateFilter,
  defaultFilters,
  filters,
  error,
  skip,
  limit,
  loadMore,
  showLoadMore,
  isFetching,
  sheetRequest,
  dateFilter = {},
  toggleResetOrderIntake,
  setLookupFilter,
  setGlobalResultsPerPage
}) => {
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const history = useHistory()
  const {classes} = useResponsiveGridStyles()

  const getEmptyMessage = (loading: boolean) => (loading ? t('loading') : t('noData'))

  const {search} = useLocation()
  const queryParams = queryString.parse(search)
  const [loadMoreClicked, setLoadMoreClicked] = React.useState<number>(0)
  const [isItemsEmpty, setIsItemsEmpty] = React.useState<boolean>(false)
  const [selectedRow, setSelectedRow] = React.useState<Order | undefined>(undefined)
  const [selectedRows, setSelectedRows] = React.useState<Order[]>([])
  const [smFilterVisible, setSmFilterVisible] = React.useState<boolean>(false)
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const [scheduledExport, setScheduledExport] = React.useState<ScheduledExport | undefined>(
    undefined
  )
  const [site, setSite] = React.useState<Site | undefined>(undefined)
  const lookupAnalyticsId = uuidV4()

  const {isReset} = useBulkCementOrderIntake()
  // getting customerId from filters rather than taking it from the customer store
  const customerId = filters?.customer?.customerId
  const projectId = filters?.project?.projectId
  const {orgUnitIds} = filters
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {name: fullName, eMail, country: userCountry} = user || {}
  const sendEmail = true
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`

  // initial setDefault Filters only once
  useEffect(() => {
    const oneWeek = oneWeekDates(language)

    let startDate = oneWeek[0]
    let endDate = oneWeek[1]

    if (queryParams.dateFrom) startDate = moment(queryParams.dateFrom as MomentInput)
    if (queryParams.dateTo) endDate = moment(queryParams.dateTo as MomentInput)

    setDefaultDateFilter({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {lookup} = filters
  const isLookupActive = view === OrderViews.LOOKUP

  const branding = useBranding()
  const country = branding.country ?? ''
  const {getFeature} = useFeaturesState()
  const {
    isGroupedDeliveries,
    isPumpServiceIncluded,
    isServiceOrderIncluded,
    isFilteringOutCancelledDeliveries,
    isFilteringCancelledOrders,
    includeDeliveryInstructions,
    expandToOrderDocuments,
    useHaulierView
  } = useAllFeaturesOrderItems()

  const isSelectionActive = () => {
    return getFeature('MultipleDownloadsOrders') && !getFeature('DisableViewDeliveryNotes')
  }
  const getItemIdentifier = (item: Order): string => {
    return isGroupedDeliveries ? item.groupId : item.orderId
  }
  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const selectedProject = useSelector<AppState, any>((state) => selectProject(state, projectId))

  const filterBarErrors: FilterBarErrorOrder[] = []

  // only fetch when one of the following props changes
  const fetchConditions = [sortedBy, sortAscending, skip, limit, filters, defaultFilters, site]

  useEffect(() => {
    if (queryParams.orderNumber) {
      setLookupFilter({
        entity: LookupSelectItems.ORDER,
        value: queryParams.orderNumber,
        label: t('order.lookup.labelorderNo')
      })
    }
  }, [queryParams.orderNumber])

  useEffect(() => {
    if (queryParams.dateFrom && queryParams.dateTo) {
      const startDateUrl = moment(queryParams.dateFrom as MomentInput)
      const endDateUrl = moment(queryParams.dateTo as MomentInput)

      setDateFilter({
        startDate: startDateUrl.toISOString(),
        endDate: endDateUrl.toISOString()
      })
    }
  }, [queryParams.dateFrom, queryParams.dateTo])

  useEffect(() => {
    if (customerId) {
      fetchOrders(
        isGroupedDeliveries,
        isFilteringOutCancelledDeliveries,
        isFilteringCancelledOrders,
        isPumpServiceIncluded,
        isServiceOrderIncluded,
        includeDeliveryInstructions,
        expandToOrderDocuments,
        useHaulierView,
        site?.siteId,
        lookupAnalyticsId
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, fetchConditions)

  useEffect(() => {
    if (!error && !isFetching && items && !(items.length > 0) && selectedCustomer) {
      filterBarErrors.push({key: 'empty', label: t('filterBar.errorEmpty')})
      setIsItemsEmpty(true)
    } else {
      setIsItemsEmpty(false)
    }
  }, [error, isLookupActive, isFetching, items, selectedCustomer])

  const columns = OrderRows({t, language, country})

  const startDate = get(dateFilter, 'startDate')
  const endDate = get(dateFilter, 'endDate')

  const getCriteria: () => Record<string, string> = () =>
    assignIn(
      {
        ...(!isLookupActive ? {startDate} : {}),
        ...(!isLookupActive ? {endDate} : {}),
        ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
        ...(isFilteringCancelledOrders
          ? {excludeCancelledOrders: false}
          : {excludeCancelledOrders: true}),
        ...(isPumpServiceIncluded ? {includePumpService: true} : {}),
        ...(isServiceOrderIncluded ? {includeServiceOrder: true} : {}),
        ...(useHaulierView ? {haulierId: customerId} : {customerId: customerId}),
        ...(site ? {siteId: site.siteId} : {}),
        ...(orgUnitIds ? {orgUnitIds} : {}),
        sortedBy: `${sortedBy} ${sortAscending ? 'asc' : 'desc'}`
      },
      ...keys(filters).map((filter: any) => {
        const filterContent = get(filters, [filter], null)
        if (filter === 'lookup') {
          if (filterContent.entity === LookupSelectItems.PO) {
            return {poNumber: filterContent.value}
          }

          const orderNumber =
            filterContent.entity === LookupSelectItems.ORDER
              ? filterContent.value
              : items[0].orderNumber
          return {orderNumber}
        }

        if (filterContent && filter === 'site') {
          return {siteId: filterContent.siteId}
        }

        return filterContent &&
          !['customer', 'date', 'supplierFilterNames', 'payer'].includes(filter)
          ? {...(filterContent ? {[filter]: filterContent} : {})}
          : null
      })
    )

  const isExportDisabled = isFetching || !getFeature('ExportOrders')

  const isOneTimeExportDisabled =
    !(items && items.length) ||
    !getFeature('ExportOrders') ||
    (dateFilter && dateRangeExceedsOneYear(startDate, endDate))

  if (!error && isLookupActive && !isFetching) {
    if (items && !(items.length > 0)) {
      const getEntityLabel = () => {
        switch (get(lookup, 'entity', '')) {
          case LookupSelectItems.BOL:
            return 'ticket'

          case LookupSelectItems.ORDER:
            return 'order'

          case LookupSelectItems.PO:
            return 'po'

          default:
            return 'ticket'
        }
      }
      filterBarErrors.push({
        key: 'noLookup',
        label: t('filterBar.noLookup', {
          lookupLabel: t(`order.lookup.${getEntityLabel()}No`),
          lookupValue: get(lookup, 'value', '')
        })
      })
    }
  }

  if (dateRangeExceedsOneYear(startDate, endDate) && selectedCustomer) {
    filterBarErrors.push({
      key: 'dateRangeExceedsOneYear',
      label: t('filterBar.errorDateRangeExceedsOneYear')
    })
  }

  if (!selectedCustomer && !isFetching) {
    filterBarErrors.push({
      key: 'noSelectedCustomer',
      label: t('filterBar.noSelectedCustomer')
    })
  }

  const displayLookupEntity = t(`order.lookup.${get(lookup, 'entity', '').toLowerCase()}No`)
  const lookupValue = get(lookup, 'value', '')

  const resultsForMessage = [
    selectedCustomer?.customerName || '',
    !isLookupActive ? dateRangeFormatter(startDate, endDate, language) : null,
    isLookupActive ? `${displayLookupEntity} ${lookupValue}` : null,
    !isLookupActive ? selectedProject?.projectName : null,
    !isLookupActive ? site?.siteName : null
  ]

  const handleRowClick = (row?: Order) => {
    if (row) {
      setShowCustomerPanel(false)
      setSelectedRow(row)
      const exposedItemIds = row?.deliveriesFromOrder
        ? row.deliveriesFromOrder.map((delivery) =>
            // if the deliveries are coming from order expanded by deliveries we have to use delivery.deliveryId
            typeof delivery === 'string' ? delivery : delivery.deliveryId
          )
        : []

      const {materialDescription, quantity, quantityUom} = row.lineItems[0]
      !isLookupActive &&
        trackEvent('hubExpand', {
          product: 'hub',
          expandedItem: 'order',
          expandedItemId: row.orderId,
          expandedItemBusinessLine: row.businessLine,
          expandedOrderMaterial: materialDescription,
          expandedOrderVolume: quantity,
          expandedOrderVolumeUOM: quantityUom,
          exposedItemIds,
          exposedItemsCount: exposedItemIds?.length || 0,
          userId: user?.user_id || '',
          userCountry: user?.country,
          orderId: row.orderId
        })
    } else {
      setSelectedRow(undefined)
    }
  }

  const handleOrderRequestBannerReload = () => {
    fetchOrders(isGroupedDeliveries)
    toggleResetOrderIntake()
  }

  const loadMoreFn = () => {
    setLoadMoreClicked(loadMoreClicked + 1)
    loadMore()
  }

  const analytics = {
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    customerId: selectedCustomer?.customerId,
    customerName: selectedCustomer?.customerName,
    filteredDateRange: {startDate, endDate}
  }

  const bulkDownloadItems = (
    deliveryIds: string[],
    orderIds: string[],
    ticketsCount: number,
    combined: boolean
  ) => {
    const downloadJobId = uuidV4()
    trackEvent('hubBulkDownloadStart', {
      product: 'hub',
      jobId: downloadJobId,
      numberOfDaysSelected: getDateRangeInDays(startDate, endDate),
      initiationSource: 'hub',
      ordersInView: items.length,
      totalItemsCount: ticketsCount + orderIds.length,
      filterCustomer: customerId,
      filterShipTo: site?.siteId,
      filterDateRange: {startDate: startDate, endDate: endDate},
      noOfFileSelected: deliveryIds.length + orderIds.length,
      exportCombined: combined
    })
    const ext = combined ? ApiDownloadRequestFormats.pdf : ApiDownloadRequestFormats.zip
    sheetRequest({
      criteria: {
        ...(deliveryIds?.length ? {deliveryIds} : {}),
        ...(orderIds?.length ? {orderIds} : {}),
        ...(isPumpServiceIncluded ? {includePumpService: true} : {})
      },
      type: ApiDownloadRequestTypes.bulkOrderDeliveryDownload,
      format: ext,
      name: `tickets-${moment().format('YYYY-MM-DD-HH-mm-ss')}.${ext}`,
      shortName: `tickets-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
      email: eMail || '',
      sendEmail,
      fullName: fullName || '',
      url,
      country: userCountry || country,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      analytics: {
        jobId: downloadJobId,
        numberOfDaysSelected: getDateRangeInDays(startDate, endDate),
        initiationSource: 'hub',
        exportCombined: combined
      },
      exportSettings: {
        combined: combined
      }
    })
  }

  const haulierId = selectedRow?.haulier?.haulierId ?? filters?.customer?.haulierId

  const sideContent = showCustomerPanel ? (
    <CustomerFeedbackPanel
      close={() => setShowCustomerPanel(false)}
      entryPoint={PageNames.ORDERS}
    />
  ) : selectedRows.length > 0 ? (
    <SelectedOrdersAside
      t={t}
      bulkDownload={bulkDownloadItems}
      orders={selectedRows}
      close={() => setSelectedRows([])}
    />
  ) : selectedRow ? (
    <OrderDetailsSM
      order={selectedRow}
      deliveries={selectedRow?.deliveriesFromOrder}
      isMobile={false}
      orderHistoryDetails={<></>}
      bulkDownload={bulkDownloadItems}
      expand={() => {
        history.push(
          useHaulierView
            ? `/orders/${getItemIdentifier(selectedRow)}?haulierId=${haulierId}`
            : `/orders/${getItemIdentifier(selectedRow)}?customerId=${
                selectedRow.customer.customerId
              }`
        )
      }}
      close={() => setSelectedRow(undefined)}
      customerId={customerId}
      analytics={analytics}
    />
  ) : null

  return (
    <Content style={{padding: smallScreen ? '8px' : 0}}>
      <Page
        data-test-id="orders-page"
        title={t('order.title')}
        headerActionContent={<OrderIntakeButtons smallScreen={smallScreen} />}
        boxed={false}
        variant={largeScreen ? 'withDetails' : 'default'}
        {...(smallScreen ? {px: 0, py: 0} : {py: 1})}
      >
        <Box data-test-id="orders-filters-part">
          <Filters
            errors={filterBarErrors}
            queryParams={queryParams}
            customerId={selectedCustomer?.customerId || ''}
            site={site}
            setSite={setSite}
            lookupAnalyticsId={lookupAnalyticsId}
            userId={user?.user_id || ''}
            xs={smallScreen}
            isVisible={smFilterVisible}
            setIsVisible={setSmFilterVisible}
            numberOfItems={items?.length}
            onSearch={setLookupFilter}
            apply={() => setSmFilterVisible(false)}
          />
        </Box>
        <Grid
          container
          columnSpacing={2}
          style={{position: 'relative', minHeight: ResponsiveHeightWithFilters}}
        >
          <Grid item xs={12} lg={8} height={!smallScreen ? ResponsiveHeightWithFilters : 'initial'}>
            <Paper
              elevation={4}
              aria-label="Order list"
              className={classes.gridItem}
              style={{height: '100%', paddingBottom: 20}}
            >
              {error ? (
                <ErrorText
                  label={t(error.translationKey)}
                  showRetry={error?.callback}
                  onRetry={error.callback}
                />
              ) : null}

              <ExportScheduledInfo scheduledExport={scheduledExport} />

              {customerId && (
                <>
                  {!error && selectedCustomer && !smallScreen ? (
                    <DocumentActionsBar>
                      <ResultsPerPageSelector
                        data-test-id="results-per-page-selector"
                        onClick={setGlobalResultsPerPage}
                      />
                      <Box display="flex">
                        {branding.digitalDocketsGuideLink ? (
                          <Box display="flex" paddingRight={3}>
                            <OpenInNewTabButton
                              data-test-id="digital-dockets-guide-button"
                              title={t('order.digitalDocketsGuideLink')}
                              link={branding.digitalDocketsGuideLink}
                              trackingEventName="hubDigitalDocketsGuideClick"
                              trackingEventParams={{
                                product: 'hub',
                                analyticsId: uuidV4(),
                                userId: user?.user_id || '',
                                userCountry: user?.country,
                                customerId,
                                customerName: selectedCustomer?.customerName
                              }}
                            />
                          </Box>
                        ) : null}
                        <ExportDropdown
                          dataTestId="orders-export-dropdown"
                          disabled={isExportDisabled}
                          oneTimeExportDisabled={isOneTimeExportDisabled}
                          downloadType={ApiDownloadRequestTypes.orderDeliveryExport}
                          getCriteria={getCriteria}
                          customer={selectedCustomer}
                          entryPoint="order"
                          trackingParams={{
                            filterShipTo: site?.siteId || '',
                            filterDateRangeStart: startDate,
                            filterDateRangeEnd: endDate,
                            filterDateRangeDuration: getDateRangeInDays(startDate, endDate),
                            lookUpQuery: isLookupActive ? lookupValue : '',
                            lookUpCategory: isLookupActive ? lookup.entity : ''
                          }}
                          onExportScheduled={setScheduledExport}
                        />
                      </Box>
                    </DocumentActionsBar>
                  ) : null}
                  {isReset ? (
                    <OrderRequestBanner
                      onReload={handleOrderRequestBannerReload}
                      customerId={customerId}
                    />
                  ) : null}
                  <FailedOrderRequestBanner customerId={customerId} />
                  {!error && selectedCustomer ? (
                    <>
                      {!smallScreen ? <ResultsForMessage messageList={resultsForMessage} /> : null}
                      {!smallScreen ? (
                        <Box px={2}>
                          <InformationBanner
                            message={t('checkboxInformationBanner.orders')}
                            pageName="orders"
                            tableHasData={!isFetching && items?.length > 0}
                          />
                          <ResponsiveTable
                            columns={columns}
                            rows={items}
                            onSort={(e, sortKey: string) => {
                              setSortKey(sortKey)
                            }}
                            sortedBy={sortedBy}
                            sortingOrder={sortAscending ? 'asc' : 'desc'}
                            loading={isFetching && skip === 0}
                            selectionEnabled={isSelectionActive()}
                            isRowSelectable={() => true}
                            getSelectedRows={(selectedRows) => setSelectedRows([...selectedRows])}
                            keyField="orderId"
                            onRowClick={handleRowClick}
                            emptyMessage={
                              <Typography variant="caption">
                                {getEmptyMessage(isFetching)}
                              </Typography>
                            }
                            rowDetailsAvailable
                            promoBanner={
                              <CustomerFeedbackBanner
                                setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
                                entryPoint={PageNames.ORDERS}
                              />
                            }
                          />
                        </Box>
                      ) : (
                        <Box mx={2}>
                          <OrdersCardList
                            rows={items}
                            loading={isFetching && skip === 0}
                            keyField="orderId"
                            fullScreen={smallScreen}
                            countryCode={user?.country || ''}
                            customerId={customerId}
                            analytics={analytics}
                            screenSize={screenSize}
                            viewOrderDetailsPage={(row: Order) => {
                              history.push(
                                `/orders/${getItemIdentifier(row)}?customerId=${
                                  row.customer.customerId
                                }`
                              )
                            }}
                          />
                        </Box>
                      )}
                      {showLoadMore && (
                        <LoadMore
                          onLight
                          isFetching={isFetching}
                          skip={skip}
                          onLoadMoreClick={loadMoreFn}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </Paper>
          </Grid>

          {largeScreen ? (
            <Grid
              item
              lg={4}
              height={ResponsiveHeightWithFilters}
              data-test-id={`order-details-${selectedRow?.orderId}`}
            >
              <Paper elevation={4} className={classes.gridItem} style={{height: '100%'}}>
                {sideContent ? (
                  sideContent
                ) : (
                  <OrderNotSelected
                    selectionEnabled={false}
                    fullScreen={smallScreen}
                    isMediumScreen={screenSize === 'md'}
                    countryCode={user?.country || ''}
                    customerId={customerId}
                    analytics={analytics}
                    screenSize={screenSize}
                  />
                )}
              </Paper>
            </Grid>
          ) : null}
          {screenSize === 'md' && sideContent ? (
            <Box
              position="absolute"
              right={0}
              top={0}
              width={2 / 5}
              height={ResponsiveHeightWithFilters}
            >
              <Slide
                in={Boolean(sideContent)}
                direction="left"
                mountOnEnter
                unmountOnExit
                data-test-id={`slide-${selectedRow?.orderId || 'selected-orders'}`}
                className={classes.gridItem}
              >
                <Paper elevation={4} style={{height: '100%'}}>
                  {sideContent}
                </Paper>
              </Slide>
            </Box>
          ) : null}
        </Grid>
      </Page>
    </Content>
  )
}

export default OrderPage

import {ParsedQuery} from 'query-string'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {PageNames} from '../../../common/constants'
import {FilterDefinition} from '../../../common/types'
import SupplierNameFilterOrders from '../../../Molecules/SupplierFilter/SupplierFilterOrders'
import CustomersFilter from '../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../Organisms/Customers/CustomerSimpleLookup'
import {useFeaturesState} from '../../../Organisms/Features'
import {GenericFilterBar} from '../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../Organisms/Filters/useFilters'
import ProjectsSitesFilter from '../../../Organisms/Projects/ProjectsSitesFilter'
import {Site} from '../../../Organisms/SitesTypeahead'
import {SitesFilter} from '../../../Organisms/SitesTypeahead/SitesTypeahead'
import {usePermissions} from '../../../Permissions'
import {setFilter} from '../Order.action'
import {removeTestStatusFilter} from '../Order.filters'
import {useAllFeaturesOrderItems} from '../Order.utils'

import {status} from './constants'
import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import OrderStatusFilter from './OrderStatusFilter'
import SitesFilterOld from './SitesFilter'
import TestStatusFilter from './TestStatusFilter'

export interface FilterBarErrorOrder {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorOrder[]
  country?: string
  queryParams?: ParsedQuery<string>
  xs?: boolean
  customerId: string
  site?: Site
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
  lookupAnalyticsId?: string
  userId?: string
  // mobile part
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply: () => void
  onSearch?: ({entity, value, label}: {entity: any; value: any; label: any}) => void
}

const Filters: React.FC<Props> = ({
  customerId,
  site,
  setSite,
  xs = false,
  lookupAnalyticsId,
  userId,
  isVisible,
  setIsVisible,
  numberOfItems,
  apply,
  onSearch,
  ...props
}) => {
  const {displayCustomerSimpleLookup, hasPermission} = usePermissions()
  const {getFeature} = useFeaturesState()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const customerSearchByNameEnabled = getFeature('CustomersByName')
  const useHaulierView = getFeature('UseHaulierView')
  const {errors, queryParams} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')
  const {isFilteringCancelledOrders} = useAllFeaturesOrderItems()

  const hasPermissionToViewProjects =
    hasPermission('VIEW_PROJECTS') && getFeature('ProjectSitesFilter')

  const initialFilterDefinition: FilterDefinition[] = useMemo(() => {
    const filters: FilterDefinition[] = [
      {
        name: 'account-filter',
        label: t('filterMenu.account'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'date-filter',
        label: t('filterMenu.date'),
        isActive: true,
        isMandatory: true
      }
    ]

    if (useHaulierView) {
      return filters
    }

    filters.push({
      name: 'destination-filter',
      label: t('orderIntake.destination'),
      isActive: true
    })

    if (getFeature('OrderStatusFilter')) {
      filters.push({
        name: 'order-status-filter',
        label: t('order.orderStatusFilter.orderStatusFilterLabel'),
        isActive: true
      })
    }

    if (getFeature('RMCTestReports')) {
      filters.push({
        name: 'rmc-test-reports-filter',
        label: t('filterMenu.rmcTestReports'),
        isActive: true
      })
    }

    if (getFeature('ShowSupplierFilter')) {
      filters.push({
        name: 'supplier-filter',
        label: t('filterMenu.supplier'),
        isActive: true
      })
    }

    return filters
  }, [])

  const handleOnResetFilter = (filter: FilterDefinition) => {
    switch (filter.name) {
      case 'order-status-filter': {
        const allStatesKeys = isFilteringCancelledOrders
          ? Object.keys(status)
          : Object.keys(status).filter((key) => key !== 'cancelled')

        let allStates: string[] = []
        allStatesKeys.map((orderStatusKey: string) => {
          allStates = [...allStates, ...status[orderStatusKey]]
        })

        dispatch(setFilter('orderStatus', allStates.join(',')))
        break
      }

      case 'destination-filter': {
        setSite(undefined)
        break
      }

      case 'supplier-filter': {
        dispatch(setFilter('orgUnitIds', undefined))
        dispatch(setFilter('supplierFilterNames', undefined))
        break
      }

      case 'rmc-test-reports-filter': {
        dispatch(removeTestStatusFilter())
      }
    }
  }

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'orders',
    initialFilterDefinition,
    handleOnResetFilter
  )

  return (
    <GenericFilterBar
      data-test-id="orders-filters"
      filters={filtersDefinition}
      lookupFilter={
        <LookupFilter
          hasError={isLookupError}
          queryParams={queryParams}
          customerId={customerId}
          analyticsId={lookupAnalyticsId}
          userId={userId}
        />
      }
      toolbarProps={{notEndLast: true}}
      xs={xs}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      numberOfItems={numberOfItems}
      onFiltersChange={handleOnFiltersChange}
      onSearch={onSearch}
      apply={apply}
      {...props}
    >
      {getFilterValue('account-filter') &&
        (displayCustomerSimpleLookup ? (
          <CustomerSimpleLookup
            data-test-id="orders-filters-lookup"
            customerSearchByName={customerSearchByNameEnabled}
            page={PageNames.ORDERS}
          />
        ) : (
          <CustomersFilter
            onLight
            customersOnly
            useHaulierView={useHaulierView}
            page={PageNames.ORDERS}
          />
        ))}
      {getFilterValue('date-filter') && <DateFilter />}
      {getFilterValue('destination-filter') &&
        (!getFeature('DestinationSearchByName') ? (
          hasPermissionToViewProjects ? (
            <ProjectsSitesFilter onLight page={PageNames.ORDERS} />
          ) : (
            <SitesFilterOld onLight />
          )
        ) : (
          <SitesFilter
            customerId={customerId}
            limit={100}
            setSite={setSite}
            site={site}
            page={PageNames.ORDERS}
          />
        ))}
      {getFilterValue('order-status-filter') && <OrderStatusFilter />}
      {getFilterValue('rmc-test-reports-filter') && <TestStatusFilter />}
      {getFilterValue('supplier-filter') && <SupplierNameFilterOrders />}
    </GenericFilterBar>
  )
}

export default Filters

import {useState, useEffect} from 'react'
import {DisclaimerConfig, DisclaimerStaticConfig} from '../CancellationDisclaimer.types'
import {useTranslation} from 'react-i18next'
import cancellationDisclaimers from '../cancellationDisclaimers'

export const getLocalizedCancellationDisclaimerContents = (
  staticConfigs: DisclaimerStaticConfig[],
  country: string,
  language: string
): DisclaimerConfig | undefined => {
  const config = staticConfigs.find((item) => item.countries.includes(country))
  if (!config) return undefined

  const locale =
    config.locales.find((config) => config.locale?.toLowerCase() === language?.toLowerCase()) ||
    config.locales[0]

  if (!locale) return undefined

  return {
    version: config.version,
    data: locale
  }
}

export const useCancellationDisclaimerContents = (country: string) => {
  const {
    i18n: {language}
  } = useTranslation()
  const userLocale = language.toLowerCase().replace('_', '-')

  const [data, setData] = useState<DisclaimerConfig | undefined>()

  useEffect(() => {
    const fetchCancellationDisclaimerContents = () => {
      const result = getLocalizedCancellationDisclaimerContents(
        cancellationDisclaimers,
        country,
        userLocale
      )
      setData(result)
    }

    fetchCancellationDisclaimerContents()
  }, [country, userLocale])

  return {data}
}

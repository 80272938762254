import {datetimeFormatter} from '@hconnect/uikit'
import {TFunction} from 'i18next'

import {ScheduledExportType} from '../../../common/react-query/types'

function parseCriteria(criteria: string) {
  return criteria.split('.')[1]
}

export function buildDescription(schedule: ScheduledExportType, t: TFunction) {
  const criteriaParsed = JSON.parse(schedule.criteria)

  const scheduleParts = []
  if (criteriaParsed?.customerId) {
    scheduleParts.push(
      t('export.parts.customer', {customer: parseCriteria(criteriaParsed.customerId as string)})
    )
  }
  if (criteriaParsed?.siteId) {
    scheduleParts.push(
      t('export.parts.destination', {destination: parseCriteria(criteriaParsed.siteId as string)})
    )
  }
  if (criteriaParsed?.payerId) {
    scheduleParts.push(
      //
      t('export.parts.payer', {payer: parseCriteria(criteriaParsed.payerId as string)})
    )
  }

  if (criteriaParsed?.status) {
    // invoice status: open,closed
    scheduleParts.push(
      t('export.parts.invoiceStatus', {invoiceStatus: criteriaParsed.status as string})
    )
  }

  const suffix = scheduleParts.join(',')
  const nextRunTime = datetimeFormatter(schedule.nextRunTime)

  // List of orders for [Destination name], [Data range], [Test status]. Weekly scheduled export will run every Monday at 10:45 am, beginning on October 20, 2024.
  if (schedule.downloadType.toLowerCase() === 'orderdeliveryexport') {
    return t('export.listOfOrders', {suffix, nextRunTime})
  }

  if (schedule.downloadType.toLowerCase() === 'invoicedeliveryexport') {
    return t('export.listOfInvoices', {suffix, nextRunTime})
  }

  if (schedule.downloadType.toLowerCase() === 'materialtestexport') {
    return t('export.listOfMaterialTests', {suffix, nextRunTime})
  }
}

import {useNotification} from '@hconnect/uikit'
import ThrashCanIcon from '@mui/icons-material/Delete'
import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {trackEvent} from '../../../common/analytics'
import {useDeleteScheduledExports} from '../../../common/react-query/hooks/mutations/useDeleteScheduledExport'
import {useGetScheduledExports} from '../../../common/react-query/hooks/queries/useScheduledExports'
import {ScheduledExportType} from '../../../common/react-query/types'
import {buildDescription} from '../utils/generateDescription.util'

type ScheduledExportItemProps = {
  schedule: ScheduledExportType
}

const ScheduledExportItem = ({schedule}: ScheduledExportItemProps) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const {mutate} = useDeleteScheduledExports()
  const queryClient = useQueryClient()
  const description = buildDescription(schedule, t)
  const deleteSchedule = () => {
    mutate(
      {scheduledExportId: schedule.id},
      {
        onSuccess: () => {
          notify('success', t('export.deleteSuccess'))
          trackEvent('hubScheduleExportDeleted', {
            scheduleId: schedule.id,
            product: 'hub'
          })
        },
        onError: (e) => {
          notify('error', t('export.deleteError'))
          trackEvent('hubScheduleExportDeletedError', {
            errorCode: (e as AxiosError).response?.status,
            scheduleId: schedule.id
          })
        },
        onSettled: async () => {
          await queryClient.invalidateQueries('scheduledExports')
        }
      }
    )
  }
  return (
    <Box data-test-id={`scheduled-export-${schedule.id}`} display="flex" flexDirection="column">
      <Grid container mt={1}>
        <Grid item md={10}>
          <Box
            data-test-id="container-title-description"
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Box data-test-id="title">
              <Typography variant="body1" fontWeight="bold" color="textPrimary">
                {schedule.title}
              </Typography>
            </Box>
            <Box data-test-id="description">
              <Typography variant="body2" color="secondary">
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item md={2} justifyItems="end" alignContent="center">
          <Box data-test-id="action">
            <Button
              startIcon={<ThrashCanIcon />}
              onClick={deleteSchedule}
              size="small"
              color="error"
            >
              {t('export.removeScheduledExportButton')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

type ScheduledExportsProps = {
  t: TFunction
}

export const ScheduledExports = ({t}: ScheduledExportsProps) => {
  const {data, isFetching} = useGetScheduledExports()
  if (isFetching) {
    return <CircularProgress />
  }
  return (
    <Box display="flex" flexDirection="column" marginTop={4}>
      <Typography variant="body1" fontWeight="bold" color="textPrimary">
        {t('export.schedulingExportTitle')}
      </Typography>
      <Typography color="secondary" variant="body2">
        {t('export.schedulingExportSubtitle')}
      </Typography>
      <Box display="flex" flexDirection="column" pl={2} mt={2}>
        {data?.map((item) => <ScheduledExportItem key={item.id} schedule={item} />)}
      </Box>
    </Box>
  )
}
